import { Button, Flex, Loader, Paper, Text } from "@mantine/core";
import { ItemsGet200ResponseRowsInnerLotsInner, ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import useProductionPlansProductionPlanIdSplitProductLotPost from "api/splitProductLot/useSplitProductLot";
import axios from "axios";
import { useModal } from "context/ModalStackManager";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getPrinterCodeByUserWithWhoami } from "utils/checkAuth";
import { customNotification } from "utils/notificationShow";
import { setToLocaleString } from "utils/unitMark";

interface PalletBarcodeBoxProps {
    workData: ProductionPlansGet200ResponseRowsInnerWorksInner | undefined;
    lotData: ItemsGet200ResponseRowsInnerLotsInner | undefined;
}

export const PalletBarcodeBox = (params: PalletBarcodeBoxProps) => {

    const { workData, lotData } = params;
    const { closeModal } = useModal();
    const [barcodeLoading, setBarcodeLoading] = useState<boolean>(false);
    const { mutate: splitMutate, isLoading } = useProductionPlansProductionPlanIdSplitProductLotPost();
    const { t } = useTranslation();
    const [barcodeImage, setBarcodeImage] = useState<string>("");

    // const { data: inventoryData } = useInventoriesGetQuery({
    //     query: {
    //         $and: [
    //             {
    //                 itemCode: workData?.item?.code
    //             },
    //             { locationCode: workData?.equipment?.targetLocationCode ?? workData?.routingData?.operation?.toLocationCode },
    //             {
    //                 lotName: lotData?.name
    //             }
    //         ],
    //     },
    // })

    // const Barcode = inventoryData?.data?.rows && inventoryData?.data?.rows[0];

    // Barcode 데이터로 보여줄 내용
    // 품명 : Barcode.itemName
    // 규격 : Barcode.spec
    // 수량 : Barcode.quantity
    // LOT명 : Barcode.lot?.name

    // useEffect(() => {
    //     barcodePrint();
    // }, [inventoryData, Barcode]);

    // const barcodePrint = () => {
    //     fetch('http://3.37.196.98:9408/api/v3/barcode/generate', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         body: JSON.stringify({
    //             "itemName": Barcode?.itemName,
    //             "spec": Barcode?.spec === null ? "-" : Barcode?.spec,
    //             "quantity": Barcode?.quantity,
    //             "lotName": Barcode?.lot?.name,
    //             "lotId": Barcode?.lotId
    //         })
    //     }).then((res) => res.blob()) // 응답을 Blob으로 변환합니다.
    //         .then((blob) => {
    //             if (blob.type !== 'application/json') {
    //                 const url = URL.createObjectURL(blob); // Blob으로부터 URL을 생성합니다.
    //                 setBarcodeImage(url); // URL을 상태에 저장합니다.
    //             }
    //         })
    //         .catch((error) => {
    //             console.error('Error:', error);
    //         });
    // };


    // {
    //     “company_code”: “7KH99D”,
    //     “lot_name”: [“240702-296#7"],
    //     “printer_code”: “Printer1",
    //     “quantity”:1,
    //     “location_code”:[“32”],
    //     “item_code”:[“3S22N000040”]
    // }

    const onSeparatePallet = async () => {
        setBarcodeLoading(true);


        try {
            const response = await axios.post(`${process.env.REACT_APP_COMPANY_BARCODE_ADDRESS_INVENTORY}`, {
                company_code: process.env.REACT_APP_COMPANY_CODE,
                lot_name: [lotData?.name],
                printer_code: await getPrinterCodeByUserWithWhoami(),
                quantity: 1, // 몇 장 뽑을 것인지에 대한 quantity 정보
                location_code: [
                    workData?.targetLocationCode
                    ?? workData?.equipment?.targetLocationCode
                    ?? workData?.routingOutsourceData?.to_location_code
                    ?? workData?.routingData?.operation?.toLocationCode
                ],
                item_code: [workData?.item?.code],
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = response.data;
            closeModal({});
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setBarcodeLoading(false);
        }

        // try {
        //     const response = await fetch('https://598h0folg6.execute-api.ap-northeast-2.amazonaws.com/dev/barcode/printArray', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json'
        //         },
        //         body: JSON.stringify({
        //             "company_code": "7KH99D", // TODO: 환경변수로 관리?
        //             "lot_name": [lotData?.name],
        //             "printer_code": await getPrinterCodeByUserWithWhoami(),
        //             "quantity": 1, // 몇장 뽑을 것인지에 대한 quantity 정보
        //             "location_code": [workData?.targetLocationCode
        //                 ?? workData?.equipment?.targetLocationCode
        //                 ?? workData?.routingOutsourceData?.to_location_code
        //                 ?? workData?.routingData?.operation?.toLocationCode],
        //             "item_code": [workData?.item?.code],
        //         })
        //     });

        //     const data = await response.json();
        //     closeModal({});
        // } catch (error) {
        //     console.error('Error:', error);
        // } finally {
        //     setBarcodeLoading(false);
        // }


        Disunite(false);
    }

    const Disunite = (confirmRequired: boolean) => {
        const shouldProceed = confirmRequired ? window.confirm(t('파레트를 분리하시겠습니까?')) : true;

        if (shouldProceed) {
            splitMutate({
                productionPlanId: workData?.productionPlanId as number,
                productionPlansProductionPlanIdSplitProductLotPostRequest: {
                    quantity: workData?.currentLotSummary?.totalQuantity as string
                },
            }, {
                onSuccess: () => {
                    closeModal({});
                },
                onError: (error) => {
                    alert(t('파레트 분리에 실패했습니다.'));
                    customNotification.error({
                        message: t('파레트 분리에 실패했습니다.'),
                    })
                }
            })
        } else {
            closeModal({});
        }
    }

    if (isLoading || barcodeLoading) return <Flex w="100%" h="100%" justify="center" align="center"><Loader size="20rem" /></Flex>

    return (
        <>
            {workData ? (
                <Flex w="50rem" direction="column" gap="sm">
                    <Paper withBorder p="md">
                        <Text fz="4rem">{t("품목명")} : {workData?.item?.name}</Text>
                        <Text fz="4rem">{t("규격")} : {workData?.item?.spec ?? "-"}</Text>
                        <Text fz="4rem">{t("양품 수량")} : {setToLocaleString(workData.currentLotSummary?.totalQuantity)}</Text>
                        <Text fz="4rem">{t("LOT명")} : {lotData?.name}</Text>
                    </Paper>
                    <Flex w="100%" gap="sm">
                        <Button w="50%" size="xl" fz="2rem" color="indigo" onClick={onSeparatePallet}>
                            {t("출력")}
                        </Button>
                        <Button w="50%" size="xl" fz="2rem" color="teal" onClick={() => Disunite(true)}>{t("취소")}</Button>
                    </Flex>
                </Flex>
            ) :
                <Flex direction="column" gap="sm">
                    <Text fz="3rem">
                        {t("현재 로트에 대해 생산된 실적이 없습니다.")}
                    </Text>
                    <Text fz="3rem">
                        {t("파레트 분리를 진행하시겠습니까?")}
                    </Text>
                    <Flex gap="sm" justify={'flex-end'}>
                        <Button size="xl" color="red" onClick={() => closeModal({})}>{t("아니오")}</Button>
                        <Button size="xl" color="green" onClick={() => Disunite(false)}>{t("예")}</Button>
                    </Flex>
                </Flex>
            }
        </>
    )
}